import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, PopupRequest, RedirectRequest, EventMessage, EventType, InteractionRequiredAuthError } from '@azure/msal-browser';
import { Subject, interval, of, Observable, combineLatest, Subscription } from 'rxjs';
import { filter, takeUntil, catchError, map, mergeMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HelperService } from './services/helper.service';
import { PermissionsService } from './services/permissions.service';
import { UserSession } from './models/permissions.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'carehub';
  isIframe = false;
  loginDisplay = false;
  isUAT = false;
  envBgClass!: string;
  envTextClass!: string;
  userSession: UserSession | null = null;
  hasSchedulerAccess$: Observable<boolean>;
  schedulerButtonText$: Observable<string>;
  private readonly _destroying$ = new Subject<void>();
  private tokenExpirationTimer!: Subscription;
  private lastRoute = '/';
  isRosterPage = false;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    public permissionsService: PermissionsService,
    private helperService: HelperService,
    private router: Router
  ) {
    this.hasSchedulerAccess$ = this.permissionsService.hasAnyFeatureAccess(['roster', 'reallocate']);
    this.schedulerButtonText$ = combineLatest([
      this.permissionsService.hasFeatureAccess('roster'),
      this.permissionsService.hasFeatureAccess('reallocate')
    ]).pipe(
      map(([canRoster, canReallocate]) => {
        if (canRoster) return 'Schedule';
        if (canReallocate) return 'Reallocate';
        return '';
      })
    );

    // Track the last route for environment switching and roster page check
    this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event) => {
      this.lastRoute = event.url;
      this.isRosterPage = event.url.includes('/roster');
    });
  }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    this.setLoginDisplay();

    const savedEnv = this.helperService.getStoredValue('ecase_env', 'prod');
    this.isUAT = savedEnv === 'uat';
    this.envBgClass = this.isUAT ? 'uat-bg' : 'prod-bg';
    this.envTextClass = this.isUAT ? 'uat-text' : 'prod-text';

    // Wait for MSAL initialization before initializing user session
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        take(1),
        mergeMap(() => this.permissionsService.initializeUserSession()),
        takeUntil(this._destroying$)
      ).subscribe({
        next: () => {
          // Session initialized successfully
        },
        error: (error) => {
          console.error('Failed to initialize user session:', error);
        }
      });

    this.authService.instance.enableAccountStorageEvents();
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
      )
      .subscribe(() => {
        if (this.authService.instance.getAllAccounts().length === 0) {
          window.location.pathname = "/";
        } else {
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
      });

    this.permissionsService.userSession$.subscribe(
      session => this.userSession = session
    );

    this.startTokenExpirationCheck();
  }

  toggleEnvironment() {
    this.isUAT = !this.isUAT;
    this.envBgClass = this.isUAT ? 'uat-bg' : 'prod-bg';
    this.envTextClass = this.isUAT ? 'uat-text' : 'prod-text';
    const newEnv = this.isUAT ? 'uat' : 'prod';
    this.helperService.storeValue('ecase_env', newEnv);
    
    // Store the environment change timestamp
    localStorage.setItem('env_change_timestamp', Date.now().toString());
    
    // Force navigation to home before reload
    if (this.lastRoute !== '/') {
      window.location.pathname = '/';
    } else {
      window.location.reload();
    }
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    const activeAccount = this.authService.instance.getActiveAccount();

    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      const accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  loginPopup() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
          this.storeTokenExpiry(response);
        });
    } else {
      this.authService.loginPopup()
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
          this.storeTokenExpiry(response);
        });
    }
  }

  logout(popup?: boolean) {
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: "/"
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  storeTokenExpiry(response: AuthenticationResult) {
    const expiresOn = response.expiresOn;
    if (expiresOn) {
      localStorage.setItem('token_expiry', expiresOn.toISOString());
    }
  }

  private startTokenExpirationCheck() {
    this.tokenExpirationTimer = interval(60000).subscribe(() => {
      const account = this.authService.instance.getActiveAccount();
      if (account) {
        this.authService.acquireTokenSilent({
          scopes: environment.graphApi.scopes,
          account: account
        }).pipe(
          catchError((error: Error) => {
            if (error instanceof InteractionRequiredAuthError) {
              this.authService.acquireTokenRedirect({
                scopes: environment.graphApi.scopes
              });
            }
            return of(null);
          })
        ).subscribe();
      }
    });
  }

  reloadPage(event: Event): void {
    event.preventDefault();
    window.location.href = '/';
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
    if (this.tokenExpirationTimer) {
      this.tokenExpirationTimer.unsubscribe();
    }
  }
}
