<section id="theme-preview">
  <mat-card>
    <ng-container *ngTemplateOutlet="mainContent"></ng-container>
  </mat-card>
</section>

<ng-template #mainContent>
  <!-- Search Input -->
  <div class="search-input-container">
    <mat-form-field appearance="outline" class="w-full search-input">
      <mat-icon matPrefix>search</mat-icon>
      <input
        type="text"
        matInput
        placeholder="Search previews..."
        [formControl]="search"
      />
    </mat-form-field>
  </div>

  <!-- Autocomplete Component -->
  <div
    class="component-container"
    *ngIf="filteredPreview.includes('autocomplete')"
  >
    <h3>Autocomplete</h3>
    <mat-form-field>
      <mat-label>Number</mat-label>
      <input
        type="text"
        matInput
        placeholder="Pick one"
        aria-label="Number"
        [formControl]="myControl"
        [matAutocomplete]="auto"
      />
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
          {{ option }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
  </div>

  <!-- Badge Component -->
  <div
    class="component-container"
    *ngIf="filteredPreview.includes('badge')"
  >
    <h3>Badge</h3>
    <div matBadge="4" matBadgeOverlap="false">Text with a badge</div>
    <div matBadge="1" matBadgeSize="large">Text with large badge</div>
    <button
      mat-raised-button
      color="primary"
      matBadge="8"
      matBadgePosition="before"
      matBadgeColor="accent"
    >
      Action
    </button>
    <div>
      <mat-icon matBadge="15" matBadgeColor="warn">home</mat-icon>
      <span class="cdk-visually-hidden">
        Example with a home icon with overlaid badge showing the number 15
      </span>
    </div>
  </div>

  <!-- Bottom Sheet Component -->
  <div
    class="component-container"
    *ngIf="filteredPreview.includes('bottom sheet')"
  >
    <h3>Bottom Sheet</h3>
    <button mat-raised-button (click)="openBottomSheet()">
      Open Bottom Sheet
    </button>
  </div>

  <!-- Buttons Component -->
  <div
    class="component-container"
    *ngIf="filteredPreview.includes('buttons')"
  >
    <h3>Buttons</h3>
    <button mat-button>Basic</button>
    <button mat-raised-button>Basic</button>
    <button mat-stroked-button>Basic</button>
    <button mat-flat-button>Basic</button>
    <button mat-icon-button matTooltip="Basic" aria-label="Heart icon">
      <mat-icon>favorite</mat-icon>
    </button>
    <button mat-fab matTooltip="Primary" color="primary">
      <mat-icon>favorite</mat-icon>
    </button>
    <button mat-mini-fab matTooltip="Primary" color="primary">
      <mat-icon>favorite</mat-icon>
    </button>
    <button mat-fab extended color="primary">
      <mat-icon>favorite</mat-icon>
      Primary
    </button>
  </div>

  <!-- Button Toggle Component -->
  <div
    class="component-container"
    *ngIf="filteredPreview.includes('button toggle')"
  >
    <h3>Button Toggle</h3>
    <mat-button-toggle-group name="fontStyle" aria-label="Font Style">
      <mat-button-toggle value="bold">Bold</mat-button-toggle>
      <mat-button-toggle value="italic">Italic</mat-button-toggle>
      <mat-button-toggle value="underline">Underline</mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <!-- Radio Button Component -->
  <div
    class="component-container"
    *ngIf="filteredPreview.includes('radio button')"
  >
    <h3>Radio Button</h3>
    <mat-radio-group aria-label="Select an option">
      <mat-radio-button value="1">Option 1</mat-radio-button>
      <mat-radio-button value="2">Option 2</mat-radio-button>
    </mat-radio-group>
  </div>

  <!-- Checkbox Component -->
  <div
    class="component-container"
    *ngIf="filteredPreview.includes('checkbox')"
  >
    <h3>Checkbox</h3>
    <section class="example-section">
      <mat-checkbox class="example-margin">Check me!</mat-checkbox>
      <mat-checkbox class="example-margin" [disabled]="true">Disabled</mat-checkbox>
    </section>

    <section class="example-section">
      <span class="example-list-section">
        <mat-checkbox
          class="example-margin"
          [checked]="allComplete"
          [color]="task.color"
          [indeterminate]="someComplete()"
          (change)="setAll($event.checked)"
        >
          {{task.name}}
        </mat-checkbox>
      </span>
      <span class="example-list-section">
        <ul>
          <li *ngFor="let subtask of task.subtasks">
            <mat-checkbox
              [(ngModel)]="subtask.completed"
              [color]="subtask.color"
              (ngModelChange)="updateAllComplete()"
            >
              {{subtask.name}}
            </mat-checkbox>
          </li>
        </ul>
      </span>
    </section>
  </div>

  <!-- Slider Component -->
  <div
    class="component-container"
    *ngIf="filteredPreview.includes('slider')"
  >
    <h3>Slider</h3>
    <mat-slider min="0" max="100000" step="1000" showTickMarks discrete [displayWith]="formatLabel">
      <input matSliderThumb>
    </mat-slider>
    <mat-slider>
      <input matSliderThumb>
    </mat-slider>
    <mat-slider min="200" max="500">
      <input value="300" matSliderStartThumb>
      <input value="400" matSliderEndThumb>
    </mat-slider>
  </div>

  <!-- Progress Bar Component -->
  <div
    class="component-container"
    *ngIf="filteredPreview.includes('progress bar')"
  >
    <h3>Progress Bar</h3>
    <mat-progress-bar mode="buffer"></mat-progress-bar>
    <mat-progress-bar mode="determinate" value="40"></mat-progress-bar>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>

  <!-- Progress Spinner Component -->
  <div
    class="component-container"
    *ngIf="filteredPreview.includes('progress spinner')"
  >
    <h3>Progress Spinner</h3>
    <mat-spinner mode="determinate" value="40"></mat-spinner>
    <mat-spinner mode="indeterminate"></mat-spinner>
  </div>

  <!-- Snackbar Component -->
  <div
    class="component-container"
    *ngIf="filteredPreview.includes('snackbar')"
  >
    <h3>Snackbar</h3>
    <mat-form-field>
      <mat-label>Message</mat-label>
      <input matInput value="Disco party!" #message>
    </mat-form-field>

    <mat-form-field>
      <mat-label>Action</mat-label>
      <input matInput value="Dance" #action>
    </mat-form-field>

    <button mat-stroked-button (click)="openSnackBar(message.value, action.value)">
      Show snack-bar
    </button>
  </div>

  <!-- Dialog Component -->
  <div
    class="component-container"
    *ngIf="filteredPreview.includes('dialog')"
  >
    <h3>Dialog</h3>
    <ol>
      <li>
        <mat-form-field>
          <mat-label>What's your name?</mat-label>
          <input matInput [(ngModel)]="name">
        </mat-form-field>
      </li>
      <li>
        <button mat-raised-button (click)="openDialog()">Pick one</button>
      </li>
      <li *ngIf="animal">
        You chose: <em>{{animal}}</em>
      </li>
    </ol>
  </div>

  <!-- Chips Component -->
  <div
    class="component-container"
    *ngIf="filteredPreview.includes('chips')"
  >
    <h3>Chips</h3>
    <mat-chip-set aria-label="Fish selection">
      <mat-chip>One fish</mat-chip>
      <mat-chip>Two fish</mat-chip>
      <mat-chip>Three fish</mat-chip>
      <mat-chip disabled>Four fish</mat-chip>
    </mat-chip-set>

    <mat-chip-set aria-label="Dog selection">
      <mat-chip>
        <img matChipAvatar src="https://material.angular.io/assets/img/examples/shiba1.jpg" alt="Shiba Inu">
        Dog one
      </mat-chip>
      <mat-chip color="primary">
        <img matChipAvatar src="https://material.angular.io/assets/img/examples/shiba1.jpg" alt="Shiba Inu">
        Dog two
      </mat-chip>
      <mat-chip color="accent">
        <img matChipAvatar src="https://material.angular.io/assets/img/examples/shiba1.jpg" alt="Shiba Inu">
        Dog three
      </mat-chip>
    </mat-chip-set>

    <mat-chip-listbox class="mat-mdc-chip-set-stacked" aria-label="Color selection">
      <mat-chip-option *ngFor="let chip of availableColors" selected [color]="chip.color">
        {{chip.name}}
      </mat-chip-option>
    </mat-chip-listbox>
  </div>

  <!-- Select Component -->
  <div
    class="component-container"
    *ngIf="filteredPreview.includes('select')"
  >
    <h3>Select</h3>
    <mat-form-field>
      <mat-label>Toppings</mat-label>
      <mat-select [formControl]="toppings" multiple>
        <mat-select-trigger>
          {{toppings.value?.[0] || ''}}
          <span *ngIf="(toppings.value?.length || 0) > 1" class="example-additional-selection">
            (+{{(toppings.value?.length || 0) - 1}} {{toppings.value?.length === 2 ? 'other' : 'others'}})
          </span>
        </mat-select-trigger>
        <mat-option *ngFor="let topping of toppingList" [value]="topping">
          {{topping}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <!-- Expansion Panel Component -->
  <div
    class="component-container"
    *ngIf="filteredPreview.includes('expansion panel')"
  >
    <h3>Expansion Panel</h3>
    <mat-accordion class="w-full">
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title>This is the expansion title</mat-panel-title>
          <mat-panel-description>This is a summary of the content</mat-panel-description>
        </mat-expansion-panel-header>
        <p>This is the primary content of the panel.</p>
      </mat-expansion-panel>

      <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
          <mat-panel-title>Self aware panel</mat-panel-title>
          <mat-panel-description>
            Currently I am {{panelOpenState ? 'open' : 'closed'}}
          </mat-panel-description>
        </mat-expansion-panel-header>
        <p>I'm visible because I am open</p>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</ng-template>
