import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { StaffItem, RoleObject, Section } from '../models/ydata.model';
import { TaskItem } from '../models/task.model';
import { EventBusService } from './event-bus.service';
import { CarerStateService } from './carer-state.service';
import { HelperService } from './helper.service';

import { 
  TimeOfDay, 
  ClientType, 
  UserType, 
  SchedulerFilter
} from '../models/filter.model';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  private staffMetadata = new Map<number, StaffItem>();
  private filterState = new BehaviorSubject<SchedulerFilter>({
    timeOfDay: TimeOfDay.ANY,
    clientType: ClientType.BOTH,
    userType: UserType.BOTH,
    showShifts: true,
    showStaff: true,
    showUnallocated: true,
    showPending: true,
    showCompleted: true,
  });

  private readonly FILTER_STATE_KEY = 'filter_state';

  constructor(
    private eventBus: EventBusService,
    private carerStateService: CarerStateService,
    private helperService: HelperService
  ) {
    // Load saved filter state
    const savedState = this.helperService.getStoredValue(this.FILTER_STATE_KEY, null);
    if (savedState) {
      this.filterState.next(savedState);
    }

    // Subscribe to staff metadata updates
    this.eventBus.staffMetadata$.subscribe((ydata: RoleObject[]) => {
      const staffMetadata = this.extractStaffMetadata(ydata);
      this.setStaffMetadata(staffMetadata);
    });
  }

  // Store staff metadata for filtering
  setStaffMetadata(staff: StaffItem[]) {
    this.staffMetadata.clear();
    staff.forEach(item => {
      this.staffMetadata.set(item.key, item);
    });
  }

  // Get staff metadata
  getStaffMetadata(key: number): StaffItem | undefined {
    return this.staffMetadata.get(key);
  }

  // Update filter state
  updateFilter(updates: Partial<SchedulerFilter>) {
    const newState = {
      ...this.filterState.value,
      ...updates
    };
    this.filterState.next(newState);
    this.helperService.storeValue(this.FILTER_STATE_KEY, newState);
  }

  // Get filter state as observable
  getFilterState(): Observable<SchedulerFilter> {
    return this.filterState.asObservable();
  }

  // Get current filter state value
  getCurrentFilterState(): SchedulerFilter {
    return this.filterState.value;
  }

  // Set of rendered carer IDs from the current ydata structure
  private _renderedCarerIds = new Set<number>();

  // Get the current set of rendered carer IDs
  getRenderedCarerIds(): Set<number> {
    return this._renderedCarerIds;
  }

  // Update the set of rendered carer IDs from the ydata structure
  updateRenderedCarerIds(ydata: RoleObject[]): void {
    this._renderedCarerIds.clear();
    const extractIds = (node: RoleObject | Section | StaffItem) => {
      // Add the key if it's a staff item (not a section/role)
      if ('is_shift' in node) {
        this._renderedCarerIds.add(node.key);
      }
      // Recursively process children if they exist
      if ('children' in node && Array.isArray(node.children)) {
        node.children.forEach(child => extractIds(child));
      }
    };
    ydata.forEach(node => extractIds(node));
  }

  // Check if a task should be visible based on current filters and rendered carer IDs
  isTaskVisible(task: TaskItem, renderedCarerIds: Set<number>): boolean {
    const state = this.filterState.value;
    const viewState = this.carerStateService.getCurrentViewState();
    
    // Check if the task's carer is in the rendered structure
    const carerId = parseInt(task.carer_string);
    const isCarerRendered = !isNaN(carerId) && renderedCarerIds.has(carerId);
    
    // In showHidden view, only check if carer is rendered
    if (viewState === 'showHidden') {
      return isCarerRendered;
    }
    
    // Check if the carer is visible based on filters
    const isCarerVisible = !isNaN(carerId) ? this.isStaffVisible(carerId) : true;
    
    // If either check fails, task is not visible
    if (!isCarerRendered || !isCarerVisible) {
      return false;
    }
    
    // Time of day filtering
    let isVisibleByTime = true;
    switch (state.timeOfDay) {
      case TimeOfDay.ANY:
        isVisibleByTime = true;
        break;
      case TimeOfDay.DAY:
        isVisibleByTime = !task.is_pm_service;
        break;
      case TimeOfDay.NIGHT:
        isVisibleByTime = task.is_pm_service;
        break;
      default:
        isVisibleByTime = true;
    }

    // Client type filtering
    let isVisibleByClientType = true;
    switch (state.clientType) {
      case ClientType.BOTH:
        isVisibleByClientType = true;
        break;
      case ClientType.HPAC_ONLY:
        isVisibleByClientType = task.is_hpac;
        break;
      case ClientType.INDEPENDENT_ONLY:
        isVisibleByClientType = !task.is_hpac;
        break;
      default:
        isVisibleByClientType = true;
    }


    return isCarerVisible && isVisibleByTime && isVisibleByClientType;
  }

  // Check if a staff member should be visible based on current filters
  isStaffVisible(staffKey: number): boolean {
    const state = this.filterState.value;
    const staff = this.staffMetadata.get(staffKey);
    const viewState = this.carerStateService.getCurrentViewState();
    const carerState = this.carerStateService.getCarerState(staffKey.toString());
    
    
    if (!staff) {
      return true; // If no metadata, default to showing
    }

    // In showHidden view, show all staff
    if (viewState === 'showHidden') {
      return true;
    }

    // Carer state filtering
    let isVisibleByCarerState = true;
    switch (viewState) {
      case 'pinnedOnly':
        isVisibleByCarerState = carerState === 'pinned';
        break;
      case 'default':
        isVisibleByCarerState = carerState !== 'hidden';
        break;
    }

    if (!isVisibleByCarerState) {
      return false;
    }

    // Time of day filtering
    let isVisibleByTime = true;
    switch (state.timeOfDay) {
      case TimeOfDay.ANY:
        isVisibleByTime = true;
        break;
      case TimeOfDay.DAY:
        isVisibleByTime = !staff.is_am_never && (staff.is_am_only || !staff.is_am_never);
        break;
      case TimeOfDay.NIGHT:
        isVisibleByTime = !staff.is_am_only && (staff.is_am_never || !staff.is_am_only);
        break;
      default:
        isVisibleByTime = true;
    }

    // Client type filtering
    let isVisibleByClientType = true;
    switch (state.clientType) {
      case ClientType.BOTH:
        isVisibleByClientType = true;
        break;
      case ClientType.HPAC_ONLY:
        isVisibleByClientType = !staff.is_hpac_never && (staff.is_hpac_only || !staff.is_hpac_never);
        break;
      case ClientType.INDEPENDENT_ONLY:
        isVisibleByClientType = !staff.is_hpac_only && (staff.is_hpac_never || !staff.is_hpac_only);
        break;
      default:
        isVisibleByClientType = true;
    }

    // User type filtering
    let isVisibleByUserType = true;
    switch (state.userType) {
      case UserType.BOTH:
        isVisibleByUserType = true;
        break;
      case UserType.STAFF:
        isVisibleByUserType = !staff.is_shift;
        break;
      case UserType.SHIFTS:
        isVisibleByUserType = staff.is_shift;
        break;
      default:
        isVisibleByUserType = true;
    }

    return isVisibleByTime && isVisibleByClientType && isVisibleByUserType;
  }

  // Extract staff metadata from ydata structure
  extractStaffMetadata(ydata: RoleObject[]): StaffItem[] {
    const staff: StaffItem[] = [];

    const processNode = (node: RoleObject | Section | StaffItem) => {
      // Check if node is a StaffItem by checking for staff-specific properties
      if ('is_shift' in node) {
        staff.push({...node});
      }

      // Check if node has children (RoleObject or Section)
      if ('children' in node && Array.isArray(node.children)) {
        node.children.forEach(child => processNode(child));
      }
    };

    ydata.forEach(node => processNode(node));
    return staff;
  }
}
