import { Injectable, ElementRef } from '@angular/core';
import { Scheduler, SchedulerStatic } from "@dhx/scheduler";
import { HelperService } from './helper.service';
import { ThemeService } from './theme.service';
import { TaskService } from './task.service';
import { ChangeManagementService } from './change-management.service';
import { DragDropService } from './drag-drop.service';
import { FilterService } from './filter.service';
import { TaskItem } from '../models/task.model';
import { YDataBuilderService } from './ydata-builder.service';
import { CarerStateService } from './carer-state.service';

@Injectable({
  providedIn: 'root'
})
export class SchedulerConfigService {
  private hideTimeout: ReturnType<typeof setTimeout> | null = null;
  private readonly HIDE_DELAY = 2000; // 2 seconds

  constructor(
    private helperService: HelperService,
    private taskService: TaskService,
    private changeManagementService: ChangeManagementService,
    private dragDropService: DragDropService,
    private filterService: FilterService,
    private ydataBuilderService: YDataBuilderService,
    private themeService: ThemeService,
    private carerStateService: CarerStateService
  ) { }

  configureTimelineView(scheduler: SchedulerStatic, y_unit: { key: number | string; label: string; children?: unknown[] }[]): void {
    const currentDate = scheduler.getState().date;
    const scrollDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate(),
      6, 0, 0
    );

    scheduler.createTimelineView({
      section_autoheight: false,
      name: "timeline",
      x_unit: "minute",
      x_date: "%i",
      x_step: 10,
      x_size: 144,
      x_start: 0,
      x_length: 144,
      y_unit: y_unit,
      folder_dy: 28,
      event_dy: "full",
      event_min_dy: 50,
      dy: 62,
      dx: 250,
      y_property: "carer_string",
      render: "tree",
      scrollable: true,
      scroll_position: scrollDate,
      column_width: 40,
      second_scale: {
        x_unit: "hour",
        x_date: "%H:%i"
      }
    });
  }

  initScheduler(schedulerContainer: ElementRef): SchedulerStatic {
    const scheduler = Scheduler.getSchedulerInstance();
    this.configureSchedulerPlugins(scheduler);
    this.configureSchedulerSettings(scheduler);
    this.configureTimelineView(scheduler, []);
    this.setupSchedulerFilters(scheduler);
    scheduler.init(schedulerContainer.nativeElement, new Date(), "timeline");
    return scheduler;
  }

  private configureSchedulerPlugins(scheduler: SchedulerStatic): void {
    scheduler.plugins({
      multiselect: true,
      minical: true,
      timeline: true,
      tooltip: true,
      cookie: true,
      treetimeline: true
    });
  }

  private configureSchedulerSettings(scheduler: SchedulerStatic): void {
    scheduler.config.all_timed = true;
    scheduler.config.ajax_error = "console";
    scheduler.config.date_format = '%Y-%m-%d %H:%i:%s';
    scheduler.config.default_date = '%l, %j %F';
    scheduler.config.details_on_dblclick = true;
    scheduler.config.drag_create = false;
    scheduler.config.drag_resize = false;
    scheduler.config.drag_move = true;
    scheduler.config.dblclick_create = false;
    scheduler.config.edit_on_create = false;
    scheduler.config.icons_select = [];
    scheduler.config.show_loading = true;
    scheduler.locale.labels['timeline_tab'] = "Timeline";
    scheduler.locale.labels.section_description = "Section";
    scheduler.setSkin(this.themeService.getCurrentTheme() ? "dark" : "material");
  }

  private setupSchedulerFilters(scheduler: SchedulerStatic): void {
    scheduler['filter_timeline'] = (id: string, event: TaskItem) => {
      return this.filterService.isTaskVisible(event, this.filterService.getRenderedCarerIds());
    };

    this.filterService.getFilterState().subscribe(() => {
      if (scheduler.getState().mode) {
        scheduler.updateView();
      }
    });
  }

  setupSchedulerTemplates(scheduler: SchedulerStatic): void {
    scheduler.templates.event_bar_text = (start: Date, end: Date, appointment: TaskItem) =>
      this.helperService.getAppointmentHtml(start, end, appointment);

    scheduler.templates.tooltip_text = (start: Date, end: Date, appointment: TaskItem) =>
      this.helperService.get_tooltip_text(start, end, appointment);

    scheduler.templates.event_class = (event_start_date, event_end_date, appointment) => {
      let cssClass = (appointment.css_ecase + " " + appointment.css_custom).trim();
      if (appointment.pending_change) {
        cssClass += " pending-change";
      }
      return cssClass.trim();
    };

    scheduler.templates.tooltip_date_format = function (date: Date) {
      const formatFunc = scheduler.date.date_to_str("%d %M %Y %H:%i:%s");
      return formatFunc(date);
    }

    scheduler.templates.timeline_row_class = (section: { children?: unknown[] }) => {
      if (section.children) {
        return "folder children";
      }
      return "";
    };
  }

  /**
   * Sets up icons for all eligible carer cells
   */
  private setupCarerIcons(): void {
    const rows = document.querySelectorAll('.dhx_timeline_label_row');
    rows.forEach(row => {
      const carerId = row.getAttribute('data-row-id');
      // Skip system carers (2000* IDs)
      if (!carerId || carerId.startsWith('2000')) {
        return;
      }

      const cell = row.querySelector('.dhx_matrix_scell') as HTMLElement;
      if (cell) {
        this.addCarerIcon(cell, carerId);
      }
    });
  }

  /**
   * Adds a single icon to the cell that can toggle between hide/pin states
   */
  private addCarerIcon(cell: HTMLElement, carerId: string): void {
    if (cell.querySelector('.carer-icon-container')) return;

    const container = document.createElement('span');
    container.classList.add('carer-icon-container');

    const icon = document.createElement('span');
    icon.classList.add('carer-icon', 'material-icons');
    icon.setAttribute('data-carer-id', carerId);

    // Set initial state from CarerStateService
    const { icon: initialIcon, classes } = this.carerStateService.getIconState(carerId);
    icon.textContent = initialIcon;
    if (classes) {
      container.classList.add(classes);
    }

    container.addEventListener('click', (event) => {
      this.carerStateService.handleIconClick(event, carerId, (isDoubleClick) => {
        // Clear any existing timeout
        if (this.hideTimeout) {
          clearTimeout(this.hideTimeout);
          this.hideTimeout = null;
        }

        // Get the current view state
        const viewState = this.carerStateService.getCurrentViewState();

        // Toggle the state
        this.carerStateService.toggleCarerState(carerId, isDoubleClick);
    
        // Get the updated icon data
        const newState = this.carerStateService.getIconState(carerId);
    
        // Reset the icon to its base classes, then add the new classes
        icon.className = 'carer-icon material-icons';
        icon.textContent = newState.icon;
    
        // Also reset the container to 'carer-icon-container'
        container.className = 'carer-icon-container';
    
        // Add classes to both if you still want container styling
        if (newState.classes) {
          icon.classList.add(newState.classes);
          container.classList.add(newState.classes);
        }

        // Only trigger rebuilds if we're not in show hidden view
        if (viewState !== 'showHidden') {
          // If we're in default view and any staff member was just hidden,
          // wait 2 seconds before triggering a rebuild
          if (viewState === 'default' && newState.icon === 'visibility_off') {
            this.hideTimeout = setTimeout(() => {
              // Only emit if we're still in default view
              if (this.carerStateService.getCurrentViewState() === 'default') {
                this.carerStateService.emitStateChange();
              }
              this.hideTimeout = null;
            }, this.HIDE_DELAY);
          } else {
            // For all other state changes (except in show hidden view), emit immediately
            this.carerStateService.emitStateChange();
          }
        }
      });
    });
    

    // Stop propagation on the container to prevent cell double-click
    container.addEventListener('dblclick', (event) => {
      event.preventDefault();
      event.stopPropagation();
    });

    container.appendChild(icon);
    cell.appendChild(container);
  }

  /**
   * Highlights cells for preferred/excluded carers
   */
  private highlightPreferredExcludedCarers(task: TaskItem): void {
    const cells = document.querySelectorAll('.dhx_matrix_scell');
    cells.forEach(cell => {
      const row = cell.closest('.dhx_timeline_label_row');
      const carerId = row?.getAttribute('data-row-id');
      
      if (carerId) {
        if (task.preferred_carers?.includes(carerId)) {
          cell.classList.add('preferred-carer');
        }
        if (task.excluded_carers?.includes(carerId)) {
          cell.classList.add('excluded-carer');
        }
      }
    });
  }

  /**
   * Removes preferred/excluded highlighting from all cells
   */
  private clearPreferredExcludedHighlights(): void {
    const cells = document.querySelectorAll('.dhx_matrix_scell');
    cells.forEach(cell => {
      cell.classList.remove('preferred-carer', 'excluded-carer');
    });
  }

  /**
   * Sets up event handlers for the scheduler.
   */
  setupSchedulerEvents(scheduler: SchedulerStatic): void {
    // Handle drag start
    scheduler.attachEvent("onBeforeDrag", (id: string) => {
      const task = scheduler.getEvent(id) as TaskItem;
      if (task.preferred_carers?.length || task.excluded_carers?.length) {
        this.highlightPreferredExcludedCarers(task);
      }
      return true;
    });

    // Handle drag end
    scheduler.attachEvent("onDragEnd", () => {
      this.clearPreferredExcludedHighlights();
    });

    // Handle event validation
    scheduler.attachEvent("onBeforeEventChanged", (ev, e, is_new, original) => {
      if (new Date(ev.start_date) < new Date()) {
        this.helperService.openSnackBar('Cannot change past events', 'Dismiss');
        return false;
      }

      const isMoveAllowedResult = this.taskService.isMoveAllowed(
        ev.section_key,
        ev.section_label,
        ev.carer_string,
        ev.css_custom
      );

      if (isMoveAllowedResult.disallowReason) {
        this.helperService.openSnackBar(
          isMoveAllowedResult.disallowReason || 'This action is not allowed',
          'Dismiss'
        );
        return false;
      }

      const isTimingChange = !this.helperService.areDatesEqual(
        ev.start_date,
        new Date(original.original_start_date)
      );
      const isAllocationChange = this.taskService.hasPendingAllocationChange(ev);

      ev.pending_allocation_change = isAllocationChange;
      ev.pending_timing_change = isTimingChange;
      ev.pending_change = ev.pending_timing_change || ev.pending_allocation_change;
      ev.css_custom = isMoveAllowedResult.classString || '';

      scheduler.updateEvent(ev.id);
      this.changeManagementService.updatePendingChangesCount(scheduler.getEvents());
      return true;
    });

    // Handle folder toggle events
    scheduler.attachEvent("onAfterFolderToggle", (section: { key?: number }, isOpen: boolean) => {
      const sectionKey = section?.key;
      if (typeof sectionKey === 'number') {
        this.ydataBuilderService.saveSectionState(sectionKey, isOpen);
      }

      setTimeout(() => {
        this.setupCarerIcons(); // Re-add icons after folder toggle
        this.dragDropService.setupDragHandlersForVisibleCells();
      }, 100);
    });

    // Called when timeline scale is added or updated
    scheduler.attachEvent("onScaleAdd", () => {
      setTimeout(() => {
        this.setupCarerIcons(); // Add icons when timeline is rendered
        this.dragDropService.setupDragHandlersForVisibleCells();
      }, 100);
    });

    // Just a placeholder for event deletion logic
    scheduler.attachEvent("onBeforeEventDelete", () => {
      // Just a placeholder for event deletion logic
      return true;
    });
  }

  /**
   * Shows or destroys a mini calendar, toggling its visibility.
   */
  show_minical(scheduler: SchedulerStatic): void {
    if (!scheduler) return;

    if (scheduler.isCalendarVisible()) {
      scheduler.destroyCalendar();
    } else {
      scheduler.renderCalendar({
        position: "dhx_minical_icon",
        date: scheduler.getState().date,
        navigation: true,
        handler: (date: Date) => {
          scheduler.setCurrentView(date, 'timeline');
          scheduler.destroyCalendar();
        }
      });
    }
  }
}
