// Section key ranges for filtering
export const SECTION_KEYS = {
    // Service sections (1000xxx)
    SERVICE_START: 1000000,
    
    // Unallocated sections (2000xxx)
    UNALLOCATED_START: 2000000,
    
    // Shifts sections (3000xxx)
    SHIFTS_START: 3000000,
    
    // Staff sections (4000xxx)
    STAFF_START: 4000000
} as const;

// Helper to identify section type by key
export const getSectionType = (key: number): 'service' | 'unallocated' | 'shifts' | 'staff' => {
    const majorKey = Math.floor(key / 1000000);
    switch (majorKey) {
        case 1: return 'service';
        case 2: return 'unallocated';
        case 3: return 'shifts';
        case 4: return 'staff';
        default: return 'staff';  // Individual staff/shifts have lower numbers
    }
};

export enum UserType {
    BOTH = 'BOTH',
    STAFF = 'STAFF',
    SHIFTS = 'SHIFTS'
}

export enum TimeOfDay {
    ANY = 'ANY',
    DAY = 'DAY',
    NIGHT = 'NIGHT'
}

export enum ClientType {
    BOTH = 'BOTH',
    HPAC_ONLY = 'HPAC_ONLY',
    INDEPENDENT_ONLY = 'INDEPENDENT_ONLY'
}

// Base filter interface for common filter properties
export interface BaseFilter {
    timeOfDay: TimeOfDay;
    clientType: ClientType;
    userType: UserType;
}

// Staff-specific filters
export interface StaffFilter extends BaseFilter {
    showShifts: boolean;
    showStaff: boolean;
    showUnallocated: boolean;
}

// Task-specific filters
export interface TaskFilter extends BaseFilter {
    showPending: boolean;
    showCompleted: boolean;
}

// Combined filters for the scheduler view
export interface SchedulerFilter extends StaffFilter, TaskFilter {}
