import { Component, Input, Output, EventEmitter } from '@angular/core';

export interface MenuOption {
  icon: string;
  tooltip: string;
  value: any;
}

@Component({
  selector: 'app-mini-menu',
  template: `
    <div class="mini-menu-container" [class.visible]="isVisible"
         (mouseenter)="keepVisible()" (mouseleave)="hideMenu()"
         [style.top.rem]="buttonTop">
      <div class="mini-menu">
        <div *ngFor="let option of options" 
           class="menu-item"
           [class.active]="option.value === selectedValue"
           (click)="onOptionClick(option.value)"
           >
          <mat-icon>{{option.icon}}</mat-icon>
          <span class="menu-text">{{option.tooltip}}</span>
        </div>
      </div>
    </div>
  `,
  styles: [`
    .mini-menu-container {
      position: fixed;
      left: 4rem; /* Sidenav width */
      margin-top: -2rem; /* Half of total height (3 items * 2rem + 2 * 0.25rem padding + 2 * 0.25rem gap) */
      display: none;
      z-index: 2000;
    }

    .mini-menu-container.visible {
      display: block;
    }

    .mini-menu {
      position: absolute;
      left: -0.75rem; /* Overlap slightly with the button to ensure no gap */
      -webkit-backdrop-filter: blur(0.625rem);
      backdrop-filter: blur(0.625rem);
      box-shadow: 0 0 0.625rem 0 rgba(0, 0, 0, 0.2);
      background-color: color-mix(in srgb, var(--mat-toolbar-container-background-color) 30%, transparent);
      z-index: 2001;
      border: 0.0625rem solid color-mix(in srgb, var(--mat-toolbar-container-background-color) 50%, transparent);
      border-radius: 0.75rem;
      padding: 0.375rem;
      display: flex;
      flex-direction: column;
      gap: 0.375rem;
    }

    .menu-item {
      width: 11rem;
      height: 2rem;
      display: flex;
      align-items: center;
      gap: 0.625rem;
      padding: 0 0.625rem;
      cursor: pointer;
      border-radius: 0.25rem;
      transition: all 0.2s ease;

      border-bottom: 1px solid var(--mat-divider-color);

      &:last-child {
      border-bottom: none;
    }

      &:hover:not(.active) {
        mat-icon {
          color: var(--mat-mdc-button-icon-color);
        }
      }

      &.active {
        // background-color: color-mix(in srgb, var(--mat-toolbar-container-background-color) 50%, transparent);
        mat-icon {
          color: var(--mat-primary-background);
        }
      }

      mat-icon {
        font-size: 1.25rem;
        width: 1.25rem;
        height: 1.25rem;
        transition: all 0.2s ease;
        color: var(--mat-mdc-button-icon-color);
      }

      .menu-text {
        font-size: 0.8125rem;
        white-space: nowrap;
        color: var(--mat-mdc-button-icon-color);
        transition: all 0.2s ease;
      }

      &.active .menu-text {
        color: var(--mat-primary-background);
      }
    }
  `]
})
export class MiniMenuComponent {
  @Output() visibilityChange = new EventEmitter<boolean>();
  @Input() options: MenuOption[] = [];
  @Input() isVisible: boolean = false;
  @Input() selectedValue: any;
  @Input() buttonTop: number = 0; // Value should now be in rem units
  @Output() optionSelected = new EventEmitter<MenuOption['value']>();

  onOptionClick(value: MenuOption['value']): void {
    this.optionSelected.emit(value);
    this.hideMenu();
  }

  keepVisible(): void {
    this.visibilityChange.emit(true);
  }

  hideMenu(): void {
    this.visibilityChange.emit(false);
  }
}
