import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CarePlanService } from 'src/app/services/careplan.service';
import { StaffService } from 'src/app/services/staff.service';
import { ClientService } from 'src/app/services/client.service';
import { CarePlan, ServiceDetail } from 'src/app/models/careplan.model';
import { ServicePlanDetail, ServiceDetail as ServicePlanServiceDetail } from 'src/app/models/serviceplandetail.model';
import { TaskItem } from 'src/app/models/task.model';
import { Options } from '@angular-slider/ngx-slider';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { ProgressDialogComponent } from '../progress-dialog/progress-dialog.component';
import { EventBusService } from 'src/app/services/event-bus.service';

@Component({
  selector: 'app-care-plan-dialog',
  templateUrl: './care-plan-dialog.component.html',
  styleUrls: ['../../templates/dialog-template.css', './care-plan-dialog.component.css']
})
export class CarePlanDialogComponent implements OnInit {
  carePlan: CarePlan | null = null;
  servicePlanDetail: ServicePlanDetail | null = null;
  loading = true;
  error: string | null = null;
  selectedService: ServiceDetail | null = null;
  selectedServiceDetails: ServicePlanServiceDetail | null = null;
  initialStartTime: string = '';
  initialEndTime: string = '';
  timeChanged: boolean = true;
  rateWindow: { start_time: string, end_time: string } | null = null;
  taskItem: TaskItem;
  staffLabel: string | undefined;
  private progressDialogRef: MatDialogRef<ProgressDialogComponent> | null = null;
  profilePhotoUrl: string | null = null;
  loadingPhoto = true;

  sliderValue: number = 0;
  endValue: number = 0;
  sliderOptions: Options = {
    floor: 0,
    ceil: 1440,
    step: 5,
  };

  constructor(
    public dialogRef: MatDialogRef<CarePlanDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { event: TaskItem },
    private carePlanService: CarePlanService,
    private staffService: StaffService,
    private eventBusService: EventBusService,
    private clientService: ClientService,
    private dialog: MatDialog
  ) {
    this.taskItem = data.event;
  }

  ngOnInit() {
    if (this.taskItem.carer_string) {
      this.staffLabel = this.staffService.getStaffLabel(this.taskItem.carer_string);
    }
    this.loadCarePlan();
    this.loadProfilePhoto();
  }

  private loadProfilePhoto() {
    this.loadingPhoto = true;
    this.clientService.getClientPhoto(this.taskItem.residentID).subscribe({
      next: (blob) => {
        this.profilePhotoUrl = URL.createObjectURL(blob);
        this.loadingPhoto = false;
      },
      error: (error) => {
        console.log('No profile photo available or error loading photo');
        this.loadingPhoto = false;
        this.profilePhotoUrl = null;
      }
    });
  }

  setCurrentStaffToKey() {
    console.log('setCurrentStaffToKey called'); // Debug log
    const confirmMessage = "This change will affect all future occurrences of this recurring event. Do you want to proceed?";

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '300px',
      data: {
        title: 'Confirm Changes',
        message: confirmMessage
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Show progress dialog
        this.progressDialogRef = this.dialog.open(ProgressDialogComponent, {
          disableClose: true,
          data: {
            title: 'Updating Key Staff',
            finalStep: 'Updating staff assignment'
          }
        });

        // Call the careplan service to update key staff
        this.carePlanService.updateKeyStaff(
          this.taskItem.CCQuoteDetailID as string,
          this.taskItem.carer_string as string,
          this.taskItem.residentID
        ).subscribe({
          next: (response) => {
            if (this.progressDialogRef) {
              const progressComponent = this.progressDialogRef.componentInstance;
              progressComponent.completeRemaining();

              if (response.status === 'success') {
                progressComponent.setSuccess();
                this.eventBusService.emitKeyStaffUpdated({
                  eventId: this.taskItem.id,
                  newKeyStaff: this.taskItem.carer_string as string,
                  staffLabel: this.staffLabel || ''
                });
                
                // Trigger background refresh after successful update
                this.carePlanService.refreshClientServicePlanCache().subscribe();
              } else {
                const errorMessage = response.message || 'Failed to update key staff. Please try again or contact support.';
                progressComponent.setError(errorMessage);
              }
            }
          },
          error: (error) => {
            if (this.progressDialogRef) {
              const progressComponent = this.progressDialogRef.componentInstance;
              progressComponent.setError('Failed to update key staff. Please try again or contact support.');
            }
            console.error('Error updating staff assignment:', error);
          }
        });
      }
    });
  }

  private loadCarePlan() {
    this.carePlanService.getClientCarePlan(this.taskItem.residentID)
      .subscribe({
        next: (response) => {
          this.carePlan = response;
          this.findSelectedService();
          this.loading = false;
        },
        error: (error) => {
          this.error = 'Failed to load care plan';
          this.loading = false;
          console.error('Error loading care plan:', error);
        }
      });
  }

  private findSelectedService() {
    if (this.carePlan) {
      this.selectedService = this.carePlan.servicesDetails.find(service =>
        service.serviceFundingSources.some(source => source.CCQuoteDetailID === this.taskItem.CCQuoteDetailID)
      ) || null;

      if (this.selectedService) {
        this.initialStartTime = this.selectedService.StartTime;
        this.initialEndTime = this.formatTimeLabel(this.timeStringToMinutes(this.selectedService.StartTime) + Number(this.selectedService.Duration));
        this.loadRateWindow();
      }
    }
  }

  private loadRateWindow() {
    if (this.selectedService) {
      this.carePlanService.getRateWindow(this.selectedService)
        .subscribe({
          next: (response) => {
            this.rateWindow = response;
            this.initializeSlider();
          },
          error: (error) => {
            console.error('Error loading rate window:', error);
          }
        });
    }
  }

  private initializeSlider() {
    if (this.rateWindow) {
      let rateWindowStart = this.timeStringToMinutes(this.rateWindow.start_time);
      let rateWindowEnd = this.timeStringToMinutes(this.rateWindow.end_time);

      if (rateWindowEnd <= rateWindowStart) {
        rateWindowEnd += 1440;
      }

      const serviceDuration = Number(this.selectedService!.Duration);

      this.sliderOptions = {
        floor: rateWindowStart,
        ceil: rateWindowEnd,
        step: 5,
        showTicks: true,
        tickStep: 60,
        showTicksValues: false,
        ticksArray: this.generateTimeTicks(rateWindowStart, rateWindowEnd),
        translate: (value: number): string => {
          return this.formatTimeLabel(value);
        },
        disabled: false,
        draggableRangeOnly: true,
      } as Options;

      let currentMinutes = this.timeStringToMinutes(this.selectedService!.StartTime);

      if (currentMinutes < rateWindowStart) {
        currentMinutes += 1440;
      }

      this.sliderValue = (currentMinutes >= rateWindowStart && currentMinutes <= rateWindowEnd)
        ? Math.round(currentMinutes / 5) * 5
        : rateWindowStart;

      this.endValue = this.sliderValue + serviceDuration;
    }
  }

  onSliderChange(value: number) {
    this.sliderValue = value;
    this.endValue = value + Number(this.selectedService!.Duration);
    this.timeChanged = this.sliderValue === this.timeStringToMinutes(this.initialStartTime);
  }
  
  private timeStringToMinutes(time: string): number {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  }

  private minutesToTimeString(minutes: number): string {
    const adjustedMinutes = minutes % 1440;
    const hours = Math.floor(adjustedMinutes / 60);
    const mins = adjustedMinutes % 60;
    return `${hours.toString().padStart(2, '0')}:${mins.toString().padStart(2, '0')}`;
  }

  private generateTimeTicks(start: number, end: number): number[] {
    const ticks: number[] = [];
    for (let minute = start; minute <= end; minute += 60) {
      ticks.push(minute);
    }
    return ticks;
  }  

  formatTimeLabel(minutes: number): string {
    const adjustedMinutes = minutes % 1440;
    const hours = Math.floor(adjustedMinutes / 60);
    const mins = adjustedMinutes % 60;
    const period = hours >= 12 ? 'PM' : 'AM';
    const displayHours = hours % 12 || 12;
    return `${displayHours}:${mins.toString().padStart(2, '0')} ${period}`;
  }

  onPublish(): void {
    const service = this.selectedService;
    if (!service) return;

    const confirmMessage = "This change will affect all future occurrences of this recurring event. Do you want to proceed?";

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '300px',
      data: {
        title: 'Confirm Changes',
        message: confirmMessage
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Show progress dialog
        this.progressDialogRef = this.dialog.open(ProgressDialogComponent, {
          disableClose: true,
          data: {
            title: 'Updating Service Time',
            finalStep: 'Updating service plan timing'
          }
        });

        const newStartTime = this.minutesToTimeString(this.sliderValue);

        this.carePlanService.updateServicePlanTiming(
          this.taskItem.residentID,
          service.ID,
          newStartTime
        ).subscribe({
          next: (response) => {
            if (this.progressDialogRef) {
              const progressComponent = this.progressDialogRef.componentInstance;
              progressComponent.completeRemaining();

              if (response.status === 'success') {
                progressComponent.setSuccess();
                this.dialogRef.close({ success: true });
                
                // Trigger background refresh after successful update
                this.carePlanService.refreshClientServicePlanCache().subscribe();
              } else {
                const errorMessage = response.message || 'Failed to update service timing. Please try again or contact support.';
                progressComponent.setError(errorMessage);
              }
            }
          },
          error: (error) => {
            console.error('Error updating service timing:', error);
            if (this.progressDialogRef) {
              const progressComponent = this.progressDialogRef.componentInstance;
              progressComponent.setError('Failed to update service timing. Please try again or contact support.');
            }
          }
        });
      }
    });
  }

  onClose(): void {
    if (this.profilePhotoUrl) {
      URL.revokeObjectURL(this.profilePhotoUrl);
    }
    this.dialogRef.close();
  }
}
