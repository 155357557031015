import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StaffService } from '../../services/staff.service';

@Component({
  selector: 'app-email-mismatch-dialog',
  template: `
    <h2 mat-dialog-title>Email Address Mismatch</h2>
    <mat-dialog-content>
      <p>You authenticated with the email address "{{data.email}}", but we cannot find that email address for an eCase user.</p>
      <p>Open eCase, go to your user profile, and update your email to:</p>
      <div class="email-copy">
        <mat-form-field appearance="outline" class="full-width">
          <input matInput [value]="data.email" readonly #emailInput>
        </mat-form-field>
        <button mat-icon-button (click)="copyEmail(emailInput)">
          <mat-icon>content_copy</mat-icon>
        </button>
      </div>
      <p>If it already looks correct, try clearing your email, then click the copy button above, paste into eCase and click update.</p>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button (click)="close()">Close</button>
      <button mat-raised-button color="primary" (click)="refreshAndRetry()">
        Refresh and Try Again
      </button>
    </mat-dialog-actions>
  `,
  styles: [`
    .email-copy {
      display: flex;
      align-items: center;
      gap: 8px;
      margin: 16px 0;
    }
    .full-width {
      width: 100%;
    }
  `]
})
export class EmailMismatchDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<EmailMismatchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { email: string },
    private staffService: StaffService
  ) {}

  copyEmail(input: HTMLInputElement) {
    input.select();
    document.execCommand('copy');
    input.setSelectionRange(0, 0);
  }

  close() {
    this.dialogRef.close(false);
  }

  refreshAndRetry() {
    this.staffService.refreshStaffCache().subscribe({
      next: () => {
        this.dialogRef.close(true);
        window.location.reload();
      },
      error: () => {
        // Even if refresh fails, close dialog and reload to retry
        this.dialogRef.close(true);
        window.location.reload();
      }
    });
  }
}
