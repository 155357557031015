<div class="roster-container">
  <div *ngIf="isLoading" class="loading-overlay">
    <div class="loading-content">
      <img src="assets/hm-loading-new.gif" alt="Loading" class="loading-gif">
      <p class="loading-text mat-h2">Waiting for data from the eCase server...</p>
    </div>
  </div>

  <div id="CommunityRosterNew" [class.hidden]="isLoading">
    <div #scheduler_here class="dhx_cal_container" style="width: calc(100vw - 4rem); height: 100vh;">
      <div class="dhx_cal_navline" >
        <div class="dhx_cal_prev_button" title="Previous">&nbsp;</div>
        <div class="dhx_minical_icon" id="dhx_minical_icon" (click)="show_minical()" title="Choose Date">&nbsp;</div>
        <div class="dhx_cal_next_button" title="Next">&nbsp;</div>

        <div class="dhx_cal_date"></div>
        
        <div class="custom_nav_buttons">
          <app-publish 
            [pendingChangesCount]="pendingChangesCount" 
            (publishChanges)="publishPendingChanges()"
            (cancelPublishing)="cancelPendingChanges()"
            title="Pending Changes">
          </app-publish>
        </div>
        <div class="custom_nav_buttons">
          <app-roster-settings></app-roster-settings>
        </div>
        
      </div>
      <div class="dhx_cal_header"></div>
      <div class="dhx_cal_data"></div>
    </div>
  </div>
</div>
