import { DOCUMENT } from '@angular/common';
import { ComponentFactoryResolver, Injectable, inject } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HelperService } from './helper.service';

@Injectable({ providedIn: 'root' })
export class ThemeService {
  private document = inject(DOCUMENT);
  private isDarkSubject = new BehaviorSubject<boolean>(false);
  isDark$ = this.isDarkSubject.asObservable();

  constructor(private helperService: HelperService) {
    // Initialize dark mode
    const isDark = this.helperService.getStoredValue('prefersDarkMode', window.matchMedia('(prefers-color-scheme: dark)').matches);
    this.setTheme(isDark);

    // Initialize environment theme
    const savedEnv = this.helperService.getStoredValue('ecase_env', 'prod');
    this.setEnvironmentTheme(savedEnv);

    // Listen for environment changes
    window.addEventListener('storage', (event) => {
      if (event.key === 'ecase_env') {
        const newEnv = event.newValue || 'prod';
        this.setEnvironmentTheme(newEnv);
      }
    });
  }

  private setTheme(isDark: boolean) {
    this.isDarkSubject.next(isDark);
    if (isDark) {
      this.document.body.classList.add('dark-theme');
    } else {
      this.document.body.classList.remove('dark-theme');
    }
    this.helperService.storeValue('prefersDarkMode', isDark);
  }

  private setEnvironmentTheme(env: string) {
    this.document.body.classList.remove('prod-theme', 'uat-theme');
    this.document.body.classList.add(`${env}-theme`);
  }

  toggleTheme() {
    this.setTheme(!this.isDarkSubject.value);
  }

  getCurrentTheme(): boolean {
    return this.isDarkSubject.value;
  }
}
