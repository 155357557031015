<!-- App Logo -->
<div class="logo-section" [routerLink]="['/']" style="cursor: pointer;">
    <img [src]="isUAT ? 'assets/symbol/symbol-fill-uat-500.png' : 'assets/symbol/symbol-fill-prod-500.png'" alt="logo"
        class="logo">
</div>

<!-- Main Content Section -->
<div class="main-content">
    <!-- Filter Icons Section -->
    <div class="filter-section">

        <!-- Carer State Toggle -->
        <div class="filter-group">
            <ul class="filter-list">
                <li>
                    <div class="menu-container">
                        <button mat-icon-button
                            [class]="getCarerIconState().classes"
                            (click)="handleUserIconClick($event)"
                            (mouseenter)="onButtonMouseEnter($event, 'carerState')" 
                            (mouseleave)="onButtonMouseLeave('carerState')">
                            <mat-icon>{{getCarerIconState().icon}}</mat-icon>
                        </button>
                        <app-mini-menu 
                            [options]="carerStateOptions" 
                            [isVisible]="showCarerStateMenu"
                            [selectedValue]="viewState" 
                            [buttonTop]="carerStateButtonTop"
                            (optionSelected)="onCarerStateOptionSelected($event)"
                            (visibilityChange)="showCarerStateMenu = $event">
                        </app-mini-menu>
                    </div>
                </li>
            </ul>
        </div>

        <!-- Time of Day -->
        <div class="filter-group">
            <ul class="filter-list">
                <li>
                    <div class="menu-container">
                        <button mat-icon-button [class.active]="timeOfDay !== 'ANY'"
                            (mouseenter)="onButtonMouseEnter($event, 'time')" (mouseleave)="onButtonMouseLeave('time')">
                            <mat-icon>{{getTimeOfDayIcon()}}</mat-icon>
                        </button>
                        <app-mini-menu 
                            [options]="timeOptions" 
                            [isVisible]="showTimeMenu" 
                            [selectedValue]="timeOfDay"
                            [buttonTop]="timeButtonTop"
                            (optionSelected)="onTimeOptionSelected($event)" 
                            (visibilityChange)="showTimeMenu = $event">
                        </app-mini-menu>
                    </div>
                </li>
            </ul>
        </div>

        <!-- Client Type -->
        <div class="filter-group">
            <ul class="filter-list">
                <li>
                    <div class="menu-container">
                        <button mat-icon-button [class.active]="clientType !== 'BOTH'"
                            (mouseenter)="onButtonMouseEnter($event, 'client')" (mouseleave)="onButtonMouseLeave('client')">
                            <mat-icon>{{getClientTypeIcon()}}</mat-icon>
                        </button>
                        <app-mini-menu 
                            [options]="clientOptions" 
                            [isVisible]="showClientMenu"
                            [selectedValue]="clientType" 
                            [buttonTop]="clientButtonTop"
                            (optionSelected)="onClientOptionSelected($event)"
                            (visibilityChange)="showClientMenu = $event">
                        </app-mini-menu>
                    </div>
                </li>
            </ul>
        </div>

        
    </div>

    <!-- Footer Section -->
    <div class="footer-section">
        <button mat-icon-button [matMenuTriggerFor]="refreshMenu" matTooltip="Refresh Data"
            [matTooltipPosition]="'right'">
            <mat-icon>refresh</mat-icon>
        </button>
        <mat-menu #refreshMenu="matMenu">
            <button mat-menu-item (click)="refreshData('carePlans')">
                <mat-icon>healing</mat-icon>
                <span>Care Plans</span>
            </button>
            <button mat-menu-item (click)="refreshData('staffDetails')">
                <mat-icon>person</mat-icon>
                <span>Staff Details</span>
            </button>
            <button mat-menu-item (click)="refreshData('roles')">
                <mat-icon>work</mat-icon>
                <span>Roles</span>
            </button>
            <button mat-menu-item (click)="refreshData('clients')">
                <mat-icon>people</mat-icon>
                <span>Clients</span>
            </button>
        </mat-menu>

        <button mat-icon-button (click)="toggleTheme()" [matTooltip]="isDarkMode ? 'Light Mode' : 'Dark Mode'"
            [matTooltipPosition]="'right'">
            <mat-icon>{{ isDarkMode ? 'light_mode' : 'dark_mode' }}</mat-icon>
        </button>

        <button mat-button (click)="toggleEnvironment()" [matTooltip]="isUAT ? 'Switch to PROD' : 'Switch to UAT'"
            [matTooltipPosition]="'right'" class="env-toggle" [class.prod-button]="!isUAT" [class.uat-button]="isUAT">
            {{ isUAT ? 'UAT' : 'PROD' }}
        </button>
    </div>
</div>
