import { Injectable } from '@angular/core';
import { Section, StaffItem, RoleObject } from '../models/ydata.model';
import { StaffService } from './staff.service';
import { HelperService } from './helper.service';
import { CarerStateService } from './carer-state.service';
import { 
  ClientType, 
  UserType, 
  TimeOfDay, 
  getSectionType
} from '../models/filter.model';
import { FilterService } from './filter.service';

export interface YDataFilters {
  showShifts: boolean;
  showStaff: boolean;
  timeOfDay: TimeOfDay;
  clientType: ClientType;
  userType: UserType;
  showUnallocated?: boolean;  // Added to match StaffService filters
}

@Injectable({
  providedIn: 'root'
})
export class YDataBuilderService {
  private readonly OPEN_SECTIONS_KEY = 'open_sections';

  constructor(
    private staffService: StaffService,
    private helperService: HelperService,
    private carerStateService: CarerStateService,
    private filterService: FilterService
  ) {}

  // Public method to save section state
  public saveSectionState(sectionKey: number, isOpen: boolean): void {
    let openSections: number[] = this.helperService.getStoredValue(this.OPEN_SECTIONS_KEY, []);
    
    if (isOpen) {
      // Add to open sections if not already there
      if (!openSections.includes(sectionKey)) {
        openSections.push(sectionKey);
      }
    } else {
      // Remove from open sections
      openSections = openSections.filter(key => key !== sectionKey);
    }
    
    this.helperService.storeValue(this.OPEN_SECTIONS_KEY, openSections);
  }

  private getSectionState(sectionKey: number): boolean {
    const openSections: number[] = this.helperService.getStoredValue(this.OPEN_SECTIONS_KEY, []);
    return openSections.includes(sectionKey);
  }

  buildFilteredYData(filters: YDataFilters): RoleObject[] {
    const originalYData = this.staffService['_ydata'];
    if (!originalYData) return [];

    const viewState = this.carerStateService.getCurrentViewState();

    // Filter sections based on view state
    const rootLevelFiltered = originalYData.filter(section => {
      // In showHidden view, show all sections
      if (viewState === 'showHidden') return true;

      // Check section's carer state
      const sectionState = this.carerStateService.getCarerState(section.key.toString());
      switch (viewState) {
        case 'default':
          return sectionState !== 'hidden';
        case 'pinnedOnly':
          return sectionState === 'pinned';
        default:
          return true;
      }
    });

    // Apply remaining filters to the sections we're keeping
    const filterSection = (section: Section | StaffItem, parentHidden = false): Section | StaffItem | null => {
      const sectionState = this.carerStateService.getCarerState(section.key.toString());
      
      // If parent is hidden, hide all children regardless of their state
      if (parentHidden) return null;

      // If it's a staff item (leaf node)
      if (!('children' in section)) {
        const staff = section as StaffItem;
        
        // Check if this is an unallocated item (key between 2000000 and 3000000)
        const isUnallocated = staff.key >= 2000000 && staff.key < 3000000;
        if (isUnallocated) {
          return staff;  // Always show unallocated items
        }

        // In showHidden view, show all staff
        if (viewState === 'showHidden') {
          // Apply other filters
        } else {
          // Apply carer state filtering
          switch (viewState) {
            case 'default':
              if (sectionState === 'hidden') return null;
              break;
            case 'pinnedOnly':
              if (sectionState !== 'pinned') return null;
              break;
          }
        }

        // Apply user type filter for other staff members
        if (filters.userType === UserType.STAFF && staff.is_shift) return null;
        if (filters.userType === UserType.SHIFTS && !staff.is_shift) return null;
        
        // Apply time of day filter
        if (filters.timeOfDay === TimeOfDay.DAY) {
          if (staff.is_am_never || (!staff.is_am_only && staff.is_am_never)) return null;
        }
        if (filters.timeOfDay === TimeOfDay.NIGHT) {
          if (staff.is_am_only || (!staff.is_am_never && staff.is_am_only)) return null;
        }
        
        // Apply client type filter
        switch (filters.clientType) {
          case ClientType.HPAC_ONLY:
            if (staff.is_hpac_never || (!staff.is_hpac_only && staff.is_hpac_never)) return null;
            break;
          case ClientType.INDEPENDENT_ONLY:
            if (staff.is_hpac_only || (!staff.is_hpac_never && staff.is_hpac_only)) return null;
            break;
          case ClientType.BOTH:
          default:
            break;
        }

        return staff;
      }

      // It's a section with children
      const sectionWithChildren = section as Section;
      
      const key = sectionWithChildren.key;
      const sectionType = getSectionType(key);
      
      // Check if this section should be hidden based on its state
      let isHidden = false;
      if (viewState === 'default') {
        isHidden = sectionState === 'hidden';
      } else if (viewState === 'pinnedOnly') {
        isHidden = sectionState !== 'pinned';
      }
      // In showHidden view, nothing is hidden
      
      // Handle section visibility based on type
      switch (sectionType) {
        case 'unallocated':
          // Unallocated sections are always visible regardless of filters
          return {
            ...sectionWithChildren,
            open: this.getSectionState(key)
          };
          
        case 'shifts':
          // Hide shifts section if shifts are disabled or user type is staff-only
          if (!filters.showShifts || filters.userType === UserType.STAFF) return null;
          break;
          
        case 'staff':
          // Hide staff section if staff are disabled or user type is shifts-only
          if (!filters.showStaff || filters.userType === UserType.SHIFTS) return null;
          break;

        case 'service':
          // Service sections are handled at root level
          break;
      }

      // Filter children
      if (sectionWithChildren.children) {
        const filteredChildren = sectionWithChildren.children
          .map((child: Section | StaffItem) => filterSection(child, isHidden))
          .filter((child: Section | StaffItem | null): child is Section | StaffItem => child !== null);

        // If section has no children after filtering, remove it
        if (filteredChildren.length === 0) return null;

        return {
          ...sectionWithChildren,
          children: filteredChildren,
          open: this.getSectionState(sectionWithChildren.key)  // Apply state to nested sections too
        };
      }

      // Even for sections without children, preserve their state
      return {
        ...sectionWithChildren,
        open: this.getSectionState(sectionWithChildren.key)
      };
    };

    // Apply remaining filters to our filtered root sections
    const filteredYData = rootLevelFiltered
      .map(roleObject => {
        const filteredChildren = roleObject.children
          .map((child: Section | StaffItem) => filterSection(child))
          .filter((child: Section | StaffItem | null): child is Section | StaffItem => child !== null);

        if (filteredChildren.length === 0) return null;

        return {
          ...roleObject,
          children: filteredChildren,
          open: this.getSectionState(roleObject.key)
        };
      })
      .filter((roleObject): roleObject is RoleObject => roleObject !== null);

    // Update the filter service with the final rendered carer IDs
    // This must happen before returning so that isTaskVisible has the correct IDs
    this.filterService.updateRenderedCarerIds(filteredYData);
    
    return filteredYData;
  }
}
