import { Injectable } from '@angular/core';
import { HelperService } from './helper.service';
import { BehaviorSubject, Subject } from 'rxjs';

export type CarerState = 'hidden' | 'pinned' | null;
export type ViewState = 'default' | 'showHidden' | 'pinnedOnly';

@Injectable({ providedIn: 'root' })
export class CarerStateService {
  private readonly CARER_STATES_KEY = 'carer_states';
  private carerStates: Map<string, CarerState>;
  private clickTimeout: ReturnType<typeof setTimeout> | null = null;
  private readonly DOUBLE_CLICK_DELAY = 300;
  private viewState = new BehaviorSubject<ViewState>('default');
  
  private carerStateChanged = new Subject<void>();
  
  public viewState$ = this.viewState.asObservable();
  public carerStateChanged$ = this.carerStateChanged.asObservable();

  public emitStateChange(): void {
    console.log('Emitting carer state change...');
    this.carerStateChanged.next();
  }

  constructor(private helperService: HelperService) {
    // Load saved view state
    const savedState = this.helperService.getStoredValue('viewState', 'default');
    this.viewState.next(savedState as ViewState);

    // Load saved carer states
    const savedCarerStates = this.helperService.getStoredValue(this.CARER_STATES_KEY, {});
    this.carerStates = new Map(Object.entries(savedCarerStates));
  }

  getCarerState(carerId: string): CarerState {
    return this.carerStates.get(carerId) || null;
  }

  setCarerState(carerId: string, state: CarerState): void {
    console.log('Setting carer state:', carerId, 'to:', state);
    if (state === null) {
      this.carerStates.delete(carerId);
    } else {
      this.carerStates.set(carerId, state);
    }
    // Save to localStorage
    const statesObject = Object.fromEntries(this.carerStates);
    this.helperService.storeValue(this.CARER_STATES_KEY, statesObject);
    
    // Emit change event immediately for pinned states or view state changes
    if (state === 'pinned' || state === null) {
      console.log('Immediate state change emit for pinned/null state');
      this.carerStateChanged.next();
    }
  }

  clearClickTimeout(): void {
    if (this.clickTimeout) {
      clearTimeout(this.clickTimeout);
      this.clickTimeout = null;
    }
  }

  handleIconClick(event: MouseEvent, carerId: string, callback: (isDoubleClick: boolean) => void): void {
    event.preventDefault();
    event.stopPropagation();

    if (this.clickTimeout === null) {
      this.clickTimeout = setTimeout(() => {
        callback(false);
        this.clickTimeout = null;
      }, this.DOUBLE_CLICK_DELAY);
    } else {
      this.clearClickTimeout();
      callback(true);
    }
  }

  getIconState(carerId: string): { icon: string, classes: string } {
    const state = this.getCarerState(carerId);
    switch (state) {
      case 'hidden':
        return { icon: 'visibility_off', classes: 'active' };
      case 'pinned':
        return { icon: 'push_pin', classes: 'pinned' };
      default:
        return { icon: 'visibility', classes: '' };
    }
  }

  toggleCarerState(carerId: string, isDoubleClick: boolean): void {
    const currentState = this.getCarerState(carerId);
    
    if (isDoubleClick) {
      this.setCarerState(carerId, currentState === 'pinned' ? null : 'pinned');
    } else {
      if (currentState === 'pinned') {
        this.setCarerState(carerId, null);
      } else {
        this.setCarerState(carerId, currentState === 'hidden' ? null : 'hidden');
      }
    }
  }

  setViewState(state: ViewState): void {
    this.viewState.next(state);
    this.helperService.storeValue('viewState', state);
  }

  getCurrentViewState(): ViewState {
    return this.viewState.value;
  }
}
