<div class="home-container prod-env">
  <div class="logo-section">
    <img src="assets/symbol/symbol-fill-shadow-500.png" alt="logo" class="logo fade-in" />
    <span class="header-title">
      <span class="libre-franklin-regular">care</span>
      <span class="libre-franklin-extralight">hub systems</span>
    </span>
  </div>

  <mat-card class="toolbar-card">
    <mat-card-content>
      <div class="toolbar-content">
        <app-user-details></app-user-details>
        <div class="toolbar-spacer"></div>
        <span *ngIf="showAuthMessage$ | async" class="auth-message">
          You have successfully authenticated, but your role in eCase is not configured to utilise this application
        </span>
        <button *ngIf="hasSchedulerAccess$ | async" mat-raised-button [routerLink]="['/roster']">
          {{schedulerButtonText$ | async}}
        </button>
        <button mat-raised-button (click)="loginRedirect()" *ngIf="!loginDisplay">Login</button>
        <button mat-raised-button (click)="logout()" *ngIf="loginDisplay">Logout</button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
